import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { Page, RichText } from '@superrb/gatsby-addons/components'
import { PageStub } from '@superrb/gatsby-addons/types'
import LegalPageType from '../types/pages/legal-page'
import LegalPageImages from '../components/legal-page/images'

const LegalPage = ({
  data,
}: PageProps<{ prismicLegalPage: LegalPageType }, PageStub>) => {
  const page = data.prismicLegalPage
  if (!page) {
    return null
  }

  const {
    data: { page_title, content },
  } = page

  return (
    <>
      <Page page={page}>
        <div className="legal-page">
          {/*<LegalPageImages parallax={true} />*/}
          {page_title && <h1 className="legal-page__title">{page_title}</h1>}
          {content && (
            <RichText
              className="legal-page__content"
              field={content.richText}
            />
          )}
        </div>
      </Page>
    </>
  )
}

export const query = graphql`
  query LegalPageQuery($id: String) {
    prismicLegalPage(id: { eq: $id }) {
      ...LegalPage
    }
  }
`

export default LegalPage
